import React, { useRef } from "react";
import { Accordion, Col } from "react-bootstrap";

export const BatchActionAccordion = ({open, batchAction}) => {
    const contentHeight = useRef();
    
    return (
        <>
        <Accordion activeKey={open ? '0' : null}>
            <Accordion.Collapse eventKey="0">
                    {batchAction}
                </Accordion.Collapse>
            </Accordion>
        
            
        </>
    )
}

