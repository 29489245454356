import React, { useState, useMemo, useCallback, useRef } from 'react'; // React State Management
import { AgGridReact } from 'ag-grid-react';
import { Container, Col, Modal, Row, Form, Button} from 'react-bootstrap';
import { getNumComparators, getEnumComparators, getTextComparators } from '../../library/comparators';
import { AddFileTable } from '../Files/AddFileTable';
import { getFiles } from '../../apis/fileApi'
import SelectedFileIcon from './../../components/Files/SelectedFileIcon';  


export const SubmitJobModal = ({show, setShow}) => 
{
    const numComparators = getNumComparators();
    const enumComparators = getEnumComparators();
    const textComparators = getTextComparators();

    const [selectedFiles,setSelectedFiles] = useState([]);
    const [jobFiles,setJobFiles] = useState([]);

    const gridRef = useRef()

    const allFilesTableRef = useRef();
    const selectedGenIndexRef = useRef(-1);
    const selectedOutputIndexRef = useRef(-1);
    const handleFileChange = (event) => {
        const filesChosen = Array.from(event.target.files);
        setSelectedFiles(filesChosen.map((file) => {return {
            name: file.name,
            size: file.size,
            taskGeneration: false,
            file: file,
        }}));
        
    };

    const [jobDetails, setJobDetails] = useState('');
    const addButtonConfiguration = 
    {
        disableAdd: false,
        hideAdd: false
    }
    const onSubmit = (e) =>
    {

    }

    const handleJobDetailsChange = (e) =>
    {
        console.log(e)
    }

    
    const handleGenerationEntryPoint = (rowProps) => {
        
        handleEntryPoint(rowProps, selectedGenIndexRef);
    
        
    }
    const handleOutputEntryPoint  = (rowProps) => {
        
        
        handleEntryPoint(rowProps, selectedOutputIndexRef);
        
    };

    const handleEntryPoint=(rowProps, entryPointRef) => {
        
        // const previousSelectedIndex = rowProps.indexRef.current;
        // const selectedIndex = rowProps.rowIndex;
        // console.log(previousSelectedIndex);
        // console.log("The selected index is, ", selectedIndex);
        // const entryPoint = rowProps.entryPoint;
        // const updatedRowNode = gridRef.current.api.getRowNode(selectedIndex);
        
        // if(previousSelectedIndex === -1)
        // {
        //     updatedRowNode.setDataValue(entryPoint, true);
        //     rowProps.indexRef.current = selectedIndex;
        //     entryPointRef.current = selectedIndex;
        // }
        // else
        // {
        //     const previousIndexMatches = previousSelectedIndex === selectedIndex;
            

        //     if(previousIndexMatches)
        //     {
        //         updatedRowNode.setDataValue(entryPoint,  false);
        //         rowProps.indexRef.current = -1;
        //         entryPointRef.current = -1;
        //     }
        //     else
        //     {
        //         rowProps.indexRef.current = selectedIndex;
        //         entryPointRef.current = selectedIndex;
        //         updatedRowNode.setDataValue(entryPoint, true);
        //         gridRef.current.api.getRowNode(previousSelectedIndex).setDataValue(entryPoint, false);
        //     }
        // }
    }

    const getRows = async (params) => {

        const filters = params.filterModel;

        const response = await getFiles(filters, params.sortModel, params.startRow, params.endRow - params.startRow)
        .then(response => {
        
            return {total: response.total, data: response.data.map((fileRow => fileRow.data))};
        });
            
        // if on or after the last page, work out the last row.
        let lastRow = -1;
        if (response.total <= params.endRow) {
            lastRow = response.total;
        }
        params.successCallback(response.data, lastRow);
    }

    const defaultColDef = useMemo(() => ({
        flex: 1,
        filter: false,
        editable: false,
    }));


    const [colDefs, setColDefs] = useState([
        {field: "fileName",  checkboxSelection: true, editable: false, headerName: "File Name", filter: 'agTextColumnFilter', filterParams:{filterOptions: textComparators, maxNumConditions: 1}},
        {field: "fileSize", filter: 'agNumberColumnFilter', editable: false, filterParams:{filterOptions : numComparators, maxNumConditions: 1}},
        {field: "taskGeneration", editable: false, headerName: "Task Generation Entry Point", cellRenderer:SelectedFileIcon, cellRendererParams: { 
        handleEntryPoint: handleGenerationEntryPoint,  entryPoint : "taskGeneration", indexRef: selectedGenIndexRef }},
        {field: "taskOutput", editable: false, headerName: "Task Output Entry Point",  cellRenderer:SelectedFileIcon, cellRendererParams:{ 
        handleEntryPoint: handleOutputEntryPoint,entryPoint : "taskOutput", indexRef: selectedOutputIndexRef 
      }},
    ]);

    const addJobFiles = async (addedFiles) => {

        setJobFiles([...jobFiles, ...addedFiles]);}

    const handleSelectedJobFiles = async (jobFiles) => {
        setSelectedFiles([...selectedFiles, ...jobFiles]);
    }

    const handleAddToJobFiles = () => {
        setJobFiles([...jobFiles, ...selectedFiles]);
    }
    const defaultPageSize = 15;

    const pageSizeOptions= [5, 10, 15, 20];
    return <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
    <Modal.Header closeButton>
        <Modal.Title>Submit Job</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Form>
            <Container>
                <Row lg={12} md={12} sm={12}>
                    <Col>
                        <Form.Group>
                            <Form.Label>Job Description</Form.Label>
                            <Form.Control type="text" placeholder="Enter job description" />
                            <Form.Text className="text-muted"/>
                            
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group>
                            <Form.Label>Job Priority</Form.Label>
                            <Form.Control type="number" placeholder="Enter job priority" min="0" max="900"/>
                            <Form.Text className="text-muted"/>
                            
                        </Form.Group>
                        </Col>
                </Row>
                <Row>
                    <Form.Group>
                        <Form.Label>Windows/Linux</Form.Label>
                        <div>
                        <Form.Check type='radio' inline label="Windows" name="os" id="windows"/>

                        <Form.Check type='radio' inline label="Linux" name="os" id="linux"/>
                        </div>
                    </Form.Group>
                </Row>
                <Row lg={12} md={12} sm={12}>
                    <Col lg={5} md={5} sm={5}>
                        <Row xs={12} sm={12} md={12} lg={12}>
                            <Col xs={3} sm={3} md={3} lg={3}>
                                <h3 class="display-7">Files</h3>
                            </Col>

                            <AddFileTable fileTableId={"submit-files"} getRows={getRows} addFiles={addJobFiles} additionalColumns={[]} pageSizeSelector={pageSizeOptions}  gridRef={allFilesTableRef}  defaultPageSize={defaultPageSize} addButtonConfiguration={addButtonConfiguration}
                            handleRowSelect={handleSelectedJobFiles}/>
                        </Row>
                    </Col>
                    <Col lg={2} md={2} sm={2}>
                        <div className='center-container'>
                        <Row xs={12} sm={12} md={12} lg={12}>
                            <Button onClick={handleAddToJobFiles} className='btn-outline btn-width'><i class="bi bi-arrow-right h4"/></Button>
                        </Row>
                        <Row xs={12} sm={12} md={12} lg={12}>
                            <Button className='btn-outline btn-width'><i class="bi bi-arrow-left h4"/></Button>
                        </Row>
                        </div>
                    </Col>
                    <Col lg={5} md={5} sm={5}>
                    <Row xs={12} sm={12} md={12} lg={12}>
                            <Col xs={12} sm={12} md={10} lg={8}>
                                <h4 class="display-9">Selected Job Files</h4>
                            </Col>
                    {
                        jobFiles.length > 0? <div className="ag-theme-quartz" >
                            <AgGridReact
                                    suppressClickEdit={true}
                                    ref={gridRef}
                                    rowData={jobFiles}
                                    pagination={true}
                                    rowSelection="multiple"
                                    defaultColDef={defaultColDef}
                                    columnDefs={colDefs}
                                    paginationPageSizeSelector={pageSizeOptions}
                                    paginationAutoPageSize={true}
                                    cacheOverflowSize={2}
                                    maxConcurrentDatasourceRequests={2}
                                    infiniteInitialRowCount={1}
                                    maxBlocksInCache={2}
                                    />
                        </div> : 
                        <div className="ag-theme-quartz" >
                            <AgGridReact rowData={[]} defaultColDef={defaultColDef} columnDefs={colDefs}/>
                        </div>
                    }
                    </Row>
                    </Col>
                </Row>
            </Container>
            <div className='right-button-container'>
                <Button onClick={() => setShow(false)}>Cancel</Button>
                <Button type="submit">Submit</Button>
            </div>
            
        </Form>
    </Modal.Body>
        
        </Modal>
}