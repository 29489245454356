import React from 'react';


import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';


export const AboutPage = () => 
{
    return (
        <>
            <h3 class="display-5">About</h3>
            <Container fluid className="p-5 bg-light rounded-3">
            <h3 class="display-6 about_heading">UI Version</h3>
            <h3 class="display-6 about_heading">API Version</h3>
            <h3 class="display-6 about_heading">Deployed on</h3>
            <h3 class="display-6 about_heading">Groups</h3>
            <h3 class="display-6 about_heading">Default Group</h3>
            </Container>
        </>
    )
}