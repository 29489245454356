import { handleJsonResponse, handleFileUploadResponse, getFilterQuery,addSortingQuery } from "./api";
import config from '../config'; 
import { getUser } from '../auth';

async function getFileById (id, token) {
  const response = await fetch(`${config.API_URL}/files/${id}`,
  {
  headers: {
    Authorization: `Bearer ${token}`
  }
  });

  return await handleJsonResponse(response);
}

export async function getFilesById(fileIds) {
  const user = getUser();
  const token = user?.access_token;
  try {
    const fetchFilesPromises = fileIds.map(id => getFileById(id, token));
    return await Promise.all(fetchFilesPromises);
  } catch (error) {
  }

}
export async function getFiles(filters, sorting, start = 0, limit = 10) {
  const user = getUser();
  const token = user?.access_token;

  let filterQuery = getFilterQuery(filters);
  filterQuery = addSortingQuery(filterQuery, sorting);
  const response = await fetch(`${config.API_URL}/files?start=${start}&limit=${limit}&${filterQuery}`,    
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return await handleJsonResponse(response);
}

export async function deleteFiles(files) {
  const user = getUser();
  const token = user?.access_token;
  const fileIds = files.map((file) => file.id);
  const data = JSON.stringify(fileIds);

  const response = await fetch(`${config.API_URL}/files/batch-archive`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: data
  });
  return await handleJsonResponse(response);

} 

export async function downloadFile(fileData) {
  
  const user = getUser();
  const token = user?.access_token;

  const response = await fetch(`${config.API_URL}/files/${fileData.id}/data`,    
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then((response) => response.blob())
  .then((blob) => {
    const fileUrl = window.URL.createObjectURL(blob);
    let alink = document.createElement('a');
    alink.href = fileUrl;
    alink.download = fileData.fileName;
    alink.click();
  });

}

export async function downloadFiles(files) {
  const user = getUser();
  const token = user?.access_token;
  const fileResponses = files.map((file) => 
  {
      return downloadFile(file)
  });
  
}

export async function deleteFile(fileId) {
  const user = getUser();
  const token = user?.access_token;
  const data = JSON.stringify({"type": "archive"});
  const response = await fetch(`${config.API_URL}/files/${fileId}/actions`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: data
  });
  return await handleJsonResponse(response);
}

export async function uploadFiles(files, updateStatus, updateMessage) {
  
  const user = getUser();
  const token = user?.access_token;
  let index = 0;

  const results = await Promise.all(files.map(async (file) => {
    let fileId = "";
    try
    {
      const uploadData = {
        Type: "Input",
        Mimetype: file.type === '' ? "application/octet-stream" : file.type,
        FileSize: file.size,
        FileName: file.name,
        SupportedMethods: ["OutOfBandHttpPost"]
      }
      const url = `${config.API_URL}/files/`;
      updateStatus(index, "Pending");

      const response = await fetch(`${config.API_URL}/files/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(uploadData)
      });

      const fileStatusData ={"type": "Uploading"};
      
      const newFileResponse = await handleJsonResponse(response);

      fileId = newFileResponse.id;
      const fileUploadingResponse = await fetch(`${config.API_URL}/files/${newFileResponse.id}/actions`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
          body: JSON.stringify(fileStatusData)
        })
      const uploadingStatusResponse = await handleJsonResponse(fileUploadingResponse);

      const fileBlob = new Blob([file], {type: file.type});
      const putResponse = await fetch(`${newFileResponse.connectionString}`,{ 
        method: 'PUT',
        body: fileBlob,
        
        headers: {  
          'Content-Type': `${file.type}`,
          'Content-Length': `${file.size}`,
          'x-ms-blob-type': "BlockBlob", 
        }, 
      });
      const uploadFileResponse = await handleFileUploadResponse(putResponse);
      
      updateStatus(index, "uploadComplete");
      const fileStatusDataUploaded = {"type": "UploadComplete"};
      
      const fileUploadedResponse = await fetch(`${config.API_URL}/files/${newFileResponse.id}/actions`, 
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(fileStatusDataUploaded)
      })
      
      const statusChange = await handleJsonResponse(fileUploadedResponse);
      index++;
      
      return {status: "success", message: `File uploaded successfully ${newFileResponse.id}`, id: newFileResponse.id};
    } catch(error){
      
      
      
      updateStatus(index, "error");
      updateMessage(index, error.message);
      index++;
      
      return {status: "error", message: "Error uploading file"};
    }
      
  }));
  return results;  
}