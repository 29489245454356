import React from 'react';
import { Container, Table  } from 'react-bootstrap';
import { durationFormatter, taskProgressFormatter } from '../../library/formatters';
import { CenteredHeaderCell, CenteredCell } from '../StyledCells';

export const JobProgress  = ({jobData}) =>
{ 
    var taskSummary = jobData.taskSummary;
    return(
        <Container fluid className="bg-light"> 
            
            <Table striped size="sm">
            <thead>
                <tr>
                    <CenteredHeaderCell cellContent={"Task Status"}/>
                    <CenteredHeaderCell cellContent={"# of Tasks"}/>
                </tr>
            </thead>
                <tbody>
                    
                    <tr><CenteredCell cellContent={"Completed: "}/><CenteredCell cellContent={taskSummary.total !== 0? taskProgressFormatter(taskSummary.completed/taskSummary.total):"0%"}/></tr>
                    <tr><CenteredCell cellContent={"Failed: "}/><CenteredCell cellContent={taskSummary.total !== 0? taskProgressFormatter(taskSummary.failed/taskSummary.total) : "0%"}/></tr>
                    <tr><CenteredCell cellContent={"Progress Made: "}/><CenteredCell cellContent={`${(taskSummary.progress * 100).toFixed(2)}%`}/></tr>
                    <tr><CenteredCell cellContent={"Tasks Started: "}/><CenteredCell cellContent={taskSummary.total !== 0? taskProgressFormatter(taskSummary.started/taskSummary.total): "0%"}/></tr>
                    <tr><CenteredCell cellContent={"Total Tasks: "}/><CenteredCell cellContent={taskSummary.total}/></tr>    
                </tbody>
            </Table>
        </Container>
    )
}