import React, { useMemo } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
export const StatusCell = (rowProps) => {
    const { value, data, gridRef } = rowProps;
    if(value === 'waiting')
    {
        return <>To Be Uploaded</>
    }
    else if( value === 'pending')
    {
        return <></>
    }
    else if( value === 'error')
    {
        return <>
            <OverlayTrigger
                overlay={<Tooltip id="button-tooltip-2">{data.message}</Tooltip>}
            >
                <i class="bi bi-exclamation-circle button-table" ></i>            
            </OverlayTrigger>
            
        </>
    }
    else if( value === "uploadComplete")
    {
        return <><i class="bi bi-check2"/></>
    }
    
  
}