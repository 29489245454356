import { handleJsonResponse, getFilterQuery, addSortingQuery } from "./api";
import config from '../config';
import { getUser } from '../auth';

export async function getJobs(filters, sorting, start = 0, limit = 10) {
  
  const user = getUser();
  const token = user?.access_token;
  
  var filterQuery = getFilterQuery(filters);
  filterQuery = addSortingQuery(filterQuery, sorting);
  const response = await fetch(`${config.API_URL}/jobs?start=${start}&limit=${limit}${filterQuery}`,
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  
  return  handleJsonResponse(response);
}
  
export async function getJob(id) {
  
  const user = getUser();
  const token = user?.access_token;
  
  const response = await fetch(`${config.API_URL}/jobs/${id}`, 
  { 
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  
  return handleJsonResponse(response);
}

export async function getJobFiles(id, type, start = 0, limit = 10) {
  
  const user = getUser();
  
  const token = user?.access_token;
  const response = await fetch(`${config.API_URL}/jobs/${id}/files?type=${type}&start=${start}&limit=${limit}`, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  
  return handleJsonResponse(response);
}

export async function updateJobState(id, jobState){
  const user = getUser();
  const token = user?.access_token;
  const data = JSON.stringify({"type": jobState, "archive":  new Date()});
  
  const response = await fetch(`${config.API_URL}/jobs/${id}/actions`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: data,
  });

  return handleJsonResponse(response);
}


export async function downloadJobFiles(id) {
  const user = getUser(); 
  const token = user?.access_token;

  const response = await fetch(`${config.API_URL}/jobs/${id}/download`,
  {
    headers: {
      responseType: 'blob',
      Authorization: `Bearer ${token}`
    }
  })
  .then((response) => response.blob())
  .then((blob) => {
    const fileUrl = window.URL.createObjectURL(blob);
    let alink = document.createElement('a');
    alink.href = fileUrl;
    alink.click();
  });

}

export async function copyJob(id){
  const user = getUser();
  const token = user?.access_token;
  
  const response = await fetch(`${config.API_URL}/jobs/${id}/copy`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  return handleJsonResponse(response);
}

export async function getJobTasks(id, start = 0, limit = 10){
  const user = getUser();
  const token = user?.access_token;
  
  const response = await fetch(`${config.API_URL}/jobs/${id}/tasks?start=${start}&limit=${limit}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  
  return handleJsonResponse(response);
}

export async function getJobHistory(id){
  const user = getUser();
  const token = user?.access_token;
  const response = await fetch(`${config.API_URL}/jobs/${id}/history`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return handleJsonResponse(response);
}

export async function batchStateChange(state, jobs)
{
  const jobIds = jobs.map((job) => job.id);
  
  const user = getUser(); 
  const token = user?.access_token;
  
  const response = await fetch(`${config.API_URL}/jobs/batch/${state}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(jobIds),
  });
  
  return handleJsonResponse(response);


}

export async function batchDownloadJobFiles(jobs) {
  const user = getUser(); 
  const token = user?.access_token;
  const jobIds = jobs.map((job) => job.id);
  
  const response = await fetch(`${config.API_URL}/jobs/batch-download`,
  {
    method: 'POST',
    headers :{
      responseType: 'blob',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(jobIds),
  })
  .then((response) => response.blob())
  .then((blob) => {
    const fileUrl = window.URL.createObjectURL(blob);
    let alink = document.createElement('a');
    alink.href = fileUrl;
    alink.click();
  });

}