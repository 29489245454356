import { AgGridReact } from 'ag-grid-react';
import React, { useState, useRef, useMemo, useCallback } from 'react';
import { getTextComparators } from './../../library/comparators';
import { dateTimeFormatter } from './../../library/formatters';
import { getJobHistory } from '../../apis/jobApi';
import { JobHistoryOperation, JobHistoryOperationWithToolTip } from './JobHistoryOperation'
export const JobHistory = ({jobId}) => {

    const containerRef = useRef(null);
    const gridRef = useRef();
    const defaultPageSize = 25;
    const getHistory = async (params) => {
        const response = await getJobHistory(jobId)
            .then(response => {
                
                return {total: response.length, data: response.map((fileRow => {
                    const editedOn = fileRow.values.map(value => value.key).join(', ');
                    return {created: fileRow.created, operationSummary: `${fileRow.operation} ${editedOn}`, additionalData: fileRow.values, operation : fileRow.operation }
                }))};
        });
                
        // if on or after the last page, work out the last row.
        let lastRow = -1;
        if (response.total <= params.endRow) {
            lastRow = response.total;
        }
        params.successCallback(response.data, lastRow);
    }

    const onGridReady = useCallback( async (params) => {
        const dataSource = {
          rowCount: undefined,
          getRows: getHistory
        };
        params.api.setGridOption('datasource', dataSource);
    });

    const textComparators = getTextComparators(); 
    const [colDefs, setColDefs] = useState([
        {field: "created", headerName: "Created", filter: 'agDateColumnFilter', valueFormatter: dateTimeFormatter, filter:false},
        {field: "operationSummary", headerName: "Operation Summary",filter: 'agTextColumnFilter', filterParams:{filterOptions : textComparators, maxNumConditions: 1},flex:1.2, cellRenderer:JobHistoryOperationWithToolTip},
        {field: "operationSummary2", headerName: "Operation Summary",filter: 'agTextColumnFilter', filterParams:{filterOptions : textComparators, maxNumConditions: 1},flex:1.2, cellRenderer:JobHistoryOperation},
        {field: "operation", headerName: "Operation",filter: 'agTextColumnFilter', hide : true },
    ]);
    
    const defaultColDef = useMemo(() => {
        return {
        flex: 1
        };
    }, []);
  
    return <>
        <div className="ag-theme-quartz half-page-table table-align">
            <AgGridReact
                defaultColDef={defaultColDef}
                suppressClickEdit={true}
                ref = {gridRef}
                pagination={true}
                columnDefs={colDefs}
                enableClickSelection={false}
                rowModelType={'infinite'}
                onGridReady={onGridReady}
            />
        </div>
    </>
};

