import React, { useState, useMemo, useCallback, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import {useDropzone} from 'react-dropzone';

import { Alert, Modal, Button, Spinner } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { uploadFiles } from './../../apis/fileApi';
import { fileSizeFormatter } from './../../library/formatters';
import { StatusCell } from './StatusCell';
const RemoveRowButton = (rowProps) => {
    return <Button className="button-table" onClick={() => rowProps.handleRemove(rowProps.node)}><i className="bi bi-x"></i></Button>
}
export const AddFilesModal = ({showModal, setShowModal, refreshFilesTable}) => 
{
    const gridRef = useRef();
    const [toBeUploadedFiles, setToBeUploadedFiles] = useState([]);
    const [placeHolderFiles, setPlaceHolderFiles] = useState([]);
    const [ messageAlert, setMessageAlert] = useState({
        alertTye: "warning",
        message: "Files of the same name cannot be uploaded at the same time.",
        showAlert: false
    });
    const handleRemoveUploadedFile = useCallback((rowNodeInfo) => {
        gridRef.current.api.applyTransaction({ remove: [rowNodeInfo.data] });
        //filter out the file that was removed from toBeUploadedFiles
        setToBeUploadedFiles(prevFiles => prevFiles.filter(file => file.name !== rowNodeInfo.data.name));
        setPlaceHolderFiles(prevFiles => prevFiles.filter(file => file.name !== rowNodeInfo.data.name));
    },[]);

    const checkDuplicateFiles = (file) => {
        if(toBeUploadedFiles.find(f => f.name === file.name))
        {
            setMessageAlert({
                alertType: "warning",
                message: "Files of the same name cannot be uploaded at the same time.",
                showAlert: true
            });
            return false;
        }
        closeAlert();
        return true;
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop : (acceptedFiles) => 
        {
            const duplicateFiles  = acceptedFiles.filter(checkDuplicateFiles);
            const tableFiles = duplicateFiles.map(file => ({id: uuidv4(),name: file.name, size: file.size, status: 'waiting'}));
            gridRef.current.api.applyTransaction({add: tableFiles});
            setToBeUploadedFiles(prevFiles => [...prevFiles, ...duplicateFiles]);
        }}
    );

    const colDefs = [
        { field: "id", headerName: "ID", hide: true }, // id column is for apply transactions
        {field: "name", headerName: "File Name", flex:1, checkboxSelection: true, },
        {field: "size", headerName: "Size", flex:1, valueFormatter: fileSizeFormatter},
        {field: "status", headerName: "Status", flex:1, cellRenderer: StatusCell,cellRendererParams: {gridRef: gridRef}},
        {field: "actions", editable: false, filter:false, headerName: "Actions", cellRenderer: RemoveRowButton, cellRendererParams: {gridRef: gridRef, handleRemove: handleRemoveUploadedFile}, flex:1}
    ];

    const updateStatus = (index, newStatus) => {
        //get row node at the index
        const rowNodeData = gridRef.current.api.getRowNode(index).data;
        rowNodeData.status = newStatus;
        gridRef.current.api.applyTransaction({update: [rowNodeData]});
    }

    const updateMessage = (index, newMessage) => {
        const rowNodeData = gridRef.current.api.getRowNode(index).data;
        rowNodeData.message = newMessage;
        gridRef.current.api.applyTransaction({update: [rowNodeData]});
    }

    const handleAddFiles = async () => {
        if(toBeUploadedFiles.length == 0)
        {
            setMessageAlert({
                alertType: "danger",
                message: "No files have been selected.",
                showAlert: true
            });
        }
        else
        {
            const results = await uploadFiles(toBeUploadedFiles, updateStatus, updateMessage);
   
            const uploadResultFail = results.find(result => result.status === "error")
            if(uploadResultFail)
            {
                setMessageAlert({
                    alertType: "danger",
                    message: "Some or all files have been unsuccessfully uploaded.",
                    showAlert: true
                });
            }
            else
            {
                setPlaceHolderFiles([]);
                setToBeUploadedFiles([]);
                setMessageAlert({
                    alertType: "success",
                    message: "All files have been successfully uploaded.",
                    showAlert: true
                });
            }
        }
        refreshFilesTable();
    }


    const defaultColDef = useMemo(() => ({
        filter: false,
        editable: false,
      }));
    
    const handleCloseModal = () => {
        // setUploadedFiles([]);
        setShowModal(false);
        setPlaceHolderFiles([]);
        setToBeUploadedFiles([]);
        closeAlert();
    }

    const closeAlert = () => {
        setMessageAlert(prevState => ({ ...prevState, showAlert: false }));
    }
    return  <Modal show={showModal} fullscreen={false} onHide={handleCloseModal}>
        <Modal.Header closeButton>
            <Modal.Title>Add Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="upload-container">
            <div
                {...getRootProps({className: 'dropzone'})}
                className={`dropzone ${isDragActive ? 'active' : ''}`}>
            
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop files here, or click to select files</p>
            </div>
       
            {messageAlert.showAlert && (
                <Alert key="addFileAlert" variant={messageAlert.alertType} onClose={() => closeAlert} dismissible>
                    {messageAlert.message}
                </Alert>
            )}
                <aside>
                    <h4>Files</h4>
                    <div id={"upload-files-table"} className="ag-theme-quartz" style={{ height: "100%", width: "100%" }}>
                        <AgGridReact
                            suppressClickEdit={true}
                            ref = {gridRef}
                            domLayout={"autoHeight"}
                            defaultColDef={defaultColDef}
                            columnDefs={colDefs}
                            rowData={placeHolderFiles}
                            cacheOverflowSize={2}
                            maxConcurrentRequests={2}
                            infiniteInitialRowCount={1}
                            maxBlocksInCache={2}
                        />
                    </div>
                    <div className="right-button-container">
                        <Button onClick={handleAddFiles} className="button-table" >Add</Button>
                    </div>
                </aside>
            </div>
        </Modal.Body>
    </Modal>
}