import React from 'react';
import {Nav, Stack} from 'react-bootstrap'
import { MetadataBadge } from '../Jobs/MetadataBadge';

export const IdTableCellLink = (rowProps) => {
    
    const { value, data, gridRef } = rowProps;
    
    let path = rowProps.path
    if(rowProps.data !== undefined)
    {
        path = path.concat(rowProps.data.id);
    }
    
    return (
        rowProps.data === undefined? <div></div>  : 
        <>
        <Stack direction="horizontal" gap={2}>
            <Nav.Link className="blue-link" href={path}>{rowProps.data.id}</Nav.Link>
            <MetadataBadge index={rowProps.data.state} gridRef={gridRef}/>
            </Stack>
        </>   
    )
};