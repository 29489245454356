import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getManageProfileRoute } from '../auth';
import { useAuth } from 'react-oidc-context';
export const UserDropdownComponent  = () =>
{ 
    const goToManageProfile = () => {
        window.location.href = getManageProfileRoute(); // Navigate to a different domain);
    }

    const auth = useAuth();
    const handleLogout = async () =>
    {
        await auth.removeUser();
        auth.signoutRedirect();
    }

    return(
        <Dropdown>
            <Dropdown.Toggle className="button-header" ><i className="bi bi-person"/></Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={goToManageProfile}>Profile</Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>

            </Dropdown.Menu>
        </Dropdown>
    )
}
