import { AgGridReact } from 'ag-grid-react';
import React, { useState, useRef, useMemo } from 'react';
import { Badge } from 'react-bootstrap';
export const MetadataBadge = ({index, gridRef}) => {
    
    const setBadgeFilter = (e, badge) =>{
    
        let filterModel = gridRef.current.api.getFilterModel();
    
    
        if(filterModel.state)
        {
            delete filterModel.state;
            gridRef.current.api.setFilterModel(filterModel);
        }
        else
        {
            if(badge === "Completed")
                {
                    
                    const filterMetadata = {
                        "state": {
                            "filterType": "text",
                            "type": "=",
                            "filter": badge
                            
                        }   
                    }
                    filterModel = { ... filterModel, ...filterMetadata}
                    gridRef.current.api.setFilterModel(filterModel);
                }
                else
                {
                    const filterMetadata = {
                        "state": {
                            "filterType": "text",
                            "type": "!=",
                            "filter": "Completed"
                            
                        }   
                    }
                    filterModel = { ... filterModel, ...filterMetadata}
                    
                    gridRef.current.api.setFilterModel(filterModel);
                }
        }
    }
    
    if(index === "Completed")
    {
        return<>
            {/* <Badge bg="primary" onClick={() => setBadgeFilter("Gateway")}>Gateway</Badge> */}
            <Badge bg="primary" onClick={(e) => setBadgeFilter(e, "Completed")}>Completed</Badge>
            </>
    }
    else
    {
        return<>
            {/* <Badge bg="secondary" onClick={() => setBadgeFilter("Gateway")}>Gateway</Badge> */}
            <Badge bg="secondary" onClick={(e) => setBadgeFilter(e, "NotCompleted")}>Not Completed</Badge>
        </>
    }
    
}