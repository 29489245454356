import React from 'react';

export const CenteredCell  = ({cellContent}) =>
{   
    return<td className='center-text'>{cellContent}</td>
}


export const CenteredHeaderCell  = ({cellContent}) =>
{   
    return<th className='center-text'>{cellContent}</th>
}