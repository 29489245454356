import { useEffect } from 'react';
import { useAuth, hasAuthParams  } from 'react-oidc-context';

function ProtectedRoute({ children }) {

    const auth = useAuth();
    
    // automatically sign-in
    useEffect(() => {
      if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
          auth.signinRedirect();
      }
    }, [auth, auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);
    
    useEffect(() => {
      auth.events.addAccessTokenExpired(() => {
          auth.signinRedirect();
      });

    }, [auth]);

    if (auth.activeNavigator === "signinSilent")
        return <div>Signing you in...</div>;
    else if (auth.activeNavigator === "signoutRedirect")
        return <div>Signing you out...</div>;
    else if (auth.isLoading)
      return <div>Loading...</div>;
    else if (auth.error) 
      return <div>Oops... {auth.error.message}</div>;
    else if (!auth.isAuthenticated)
      return <div>Not logged in</div>;

    return children;
}

export default ProtectedRoute;

