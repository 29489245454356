import React, { useState, useMemo, useCallback, useRef } from 'react'; // React State Management
import { AgGridReact } from 'ag-grid-react';
import { Container, Col, Modal, Row, Form, Button} from 'react-bootstrap';
import { getNumComparators, getEnumComparators, getTextComparators } from '../../library/comparators';
import { JobAccess } from './JobAccess';

// import SelectedFileIcon from './../../components/Files/SelectedFileIcon';  


export const JobAccessModal = ({show, setShow}) => 
{
    return<Modal show={show} size="lg" onHide={() => setShow(false)}>
    <Modal.Header closeButton>
        <Modal.Title>Edit Access</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <JobAccess/>
    </Modal.Body>
     
    </Modal>

}
    