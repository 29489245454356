import React, { useMemo } from 'react';

import { AgGridReact } from 'ag-grid-react';

export const FilesTable = ({tableSize, fileTableConfiguration}) => {
    const {gridId, defaultPageSize, pageSizeOptions, colDefs, onGridReady, rowSelectionChanged, gridRef} = fileTableConfiguration;

    // Apply settings across all columns
    // const defaultColDef = useMemo(() => ({
    //     filter: true,
    //     editable: false,
    // }));
    const defaultColDef = useMemo(() => {
        return {
          flex: 1
        };
      }, []);
    const autoSizeStrategy = {
        type: 'fitGridWidth',
    };
    
    return(
        <div id={gridId} className={`ag-theme-quartz ${tableSize}`}>
            <AgGridReact
                suppressClickEdit={true}
                ref = {gridRef}
                pagination={true}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                rowSelection="multiple"
                rowModelType={'infinite'}
                onGridReady={onGridReady}
                onSelectionChanged={rowSelectionChanged}
                paginationPageSize={defaultPageSize}
                paginationPageSizeSelector={pageSizeOptions}
                rowMultiSelectWithClick={true}
                cacheOverflowSize={2}
                maxConcurrentRequests={2}
                infiniteInitialRowCount={1}
                maxBlocksInCache={2}
            />
        </div>
    )
}