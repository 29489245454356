/* Format Date Cells */
import { formatDuration, intervalToDuration } from 'date-fns';

export const dateFormatter = (params) => {
    return params.value ? new Date(params.value).toLocaleDateString( {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }) : "";
};

export const dateTimeFormatter = (params) => {
    return params.value ? new Date(params.value).toLocaleString( {
        //include hours and minutes
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }) : "";
};

export const durationFormatter = (duration) => {
    if(duration === 0)
    {
        return '0 sec(s)'
    }
    const durationInMilliseconds = duration * 1000;
    const toDuration = intervalToDuration({ start: 0, end: durationInMilliseconds });
    return formatDuration(toDuration).replace(/seconds/g, 'sec(s)');
}

export const taskProgressFormatter = (progress) => {
    return progress.toLocaleString('en-US', { style: 'percent' });
}
export const fileSizeFormatter = (params) => {
    if(params.value < fileSizes.kbInBytes)
        return params.value + " bytes";
    if(params.value < fileSizes.mbInBytes)
        return (params.value/fileSizes.kbInBytes).toFixed(2) + " KB";
    if(params.value < fileSizes.gbInBytes)
        return (params.value/fileSizes.mbInBytes).toFixed(2) + " MB";
    if(params.value < fileSizes.tbInBytes)
        return (params.value/fileSizes.gbInBytes).toFixed(2) + " GB";
    else
        return (params.value/fileSizes.tbInBytes).toFixed(2) + " TB";
    
}


const fileSizes = {
    kbInBytes : 1000,
    mbInBytes : 1000000,
    gbInBytes : 1000000000,
    tbInBytes : 1000000000000,
}

const timeDurations = {
    minInSec : 60,
    hourInSec : 3600,
    dayInSec : 86400,
}