import React, { createContext, useContext, useState } from 'react';

const DummyPermissionContext = createContext();

export const DummyPermissionProvider = ({ children }) => {
    const [hasPermission, setHasPermission] = useState(false);
    

    return(
    <DummyPermissionContext.Provider value={{ hasPermission, setHasPermission }}>
        {children}
    </DummyPermissionContext.Provider>
    )

  };

export const useDummyPermissionContext = () => {
  return useContext(DummyPermissionContext);
};
