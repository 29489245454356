import React, { useState, useEffect } from 'react';
import { Tooltip , OverlayTrigger } from 'react-bootstrap';
import { getFilesById } from '../../apis/fileApi';


export const JobHistoryOperation = (rowProps) => {
    const [updateDetails, setUpdateDetails] = useState("")
    const data = rowProps?.data;
    useEffect(() => {
        async function getData() 
        {
            if (data === undefined || data.additionalData === undefined)
                {
                    setUpdateDetails("")
                    
                }
                else
                {
                    if (data.operationSummary.includes("Add"))
                    {
                        setUpdateDetails(data.operationSummary)
                        return;
                    }
                    else if(data.operationSummary.includes("Edit Files"))
                    {
                        const diffValues = data.additionalData.map((data) => 
                        {
                            const {key, newValue, oldValue} = data;
                            const newValueArray = newValue.split(',');
                            const oldValueArray = oldValue ? oldValue.split(',') : [];
                            const diffArray = newValueArray.filter(value => !oldValueArray.includes(value));          
                            return {diff: diffArray};
                        });
                        const fileDetailsResult = getFilesById(diffValues[0].diff);
                        
                        setUpdateDetails(`${data.operationSummary}: ${(await fileDetailsResult).map(file => file.fileName)}`)
                    }
                    else if(data.operationSummary.includes("State"))
                    {
                        const oldState  = data.additionalData[0].oldValue;
                        const newState  = data.additionalData[0].newValue;
                        setUpdateDetails(`State change from ${oldState} to ${newState}`)
                    }
                    else
                    {
                        const diffValues = data.additionalData.map((data) => 
                            {
                                const {key, newValue, oldValue} = data;
                                const newValueArray = newValue.split(',');
                                const oldValueArray = oldValue ? oldValue.split(',') : [];
                                const diffArray = newValueArray.filter(value => !oldValueArray.includes(value));          
                                return {diff: diffArray};
                            });
                        
                    }
                }
        }
        getData();
    }, [])
    
    return <div>{updateDetails}</div>
}

export const JobHistoryOperationWithToolTip = (rowProps) => {
    const [updateDetails, setUpdateDetails] = useState("")
    const data = rowProps?.data;

    const fillOutDetails = async() => {

        if (data === undefined || data.additionalData === undefined)
        {
            setUpdateDetails("byew")
        }
        else
        {
            if(data.operationSummary.includes("Files"))
            {
                const diffValues = data.additionalData.map((data) => 
                {
                    const {key, newValue, oldValue} = data;
                    const newValueArray = newValue.split(',');
                    const oldValueArray = oldValue ? oldValue.split(',') : [];
                    const diffArray = newValueArray.filter(value => !oldValueArray.includes(value));          
                    return {diff: diffArray};
                });
                const fileDetails = await getFilesById(diffValues[0].diff);
                setUpdateDetails(fileDetails.map(file => file.fileName));
            }
            else if(data.operationSummary.includes("State"))
            {
                const oldState  = data.additionalData[0].oldValue;
                const newState  = data.additionalData[0].newValue;
                setUpdateDetails(`State change from ${oldState} to ${newState}`)
            }
        }
    }

    const renderTooltip = (props) => {
        return <Tooltip id="button-tooltip" {...props}>
            {updateDetails}
        </Tooltip>
    };

    if(data?.operationSummary === undefined || data?.operation == undefined)
        return <></>

    if(rowProps.data.operation !== "Add")
        return<OverlayTrigger placement='left'  overlay={renderTooltip} onEnter={fillOutDetails}>
            <div>{rowProps.data.operationSummary}</div>
        </OverlayTrigger>

    return <div>{rowProps.data.operationSummary}</div>
}