import React, { useState, useMemo, useCallback, useRef } from 'react';
import { getNumComparators, getEnumComparators, getTextComparators } from '../../library/comparators';
import { fileSizeFormatter } from '../../library/formatters';
import { Button, Col, Card, Row } from 'react-bootstrap';
import { BatchActionAccordion } from '../BatchActionAccordion';
import { BatchFileActions } from './FileActions';
import { FileActions } from './FileActions';
import { UpdateService } from '../../services/UpdateService';
import { AddFilesModal } from './AddFilesModal';
import { FilesTable } from './FilesTable';

import { CustomStateFilter } from '../Table/CustomFilters/CustomStateFilter';

export const AddFileTable =({fileTableId, getRows, additionalColumns, addFiles, addButtonConfiguration, tableSize='', columnConfiguration = { xs: 12, sm: 12, md: 12, lg: 12}, pageSizeOptions=[15, 20, 50, 100], defaultPageSize=20, handleRowSelect=null, active}) =>
{
  const gridRef = useRef();
  const [open, setOpen] = useState(false);
  const [showAddFileModal, setShowAddFileModal] = useState(false);
  const gridId = `${fileTableId}-fileGrid`;
  const numComparators = getNumComparators();
  const enumComparators = getEnumComparators();
  const textComparators = getTextComparators();

  const [colDefs, setColDefs] = useState([
    {field: "fileName", checkboxSelection: true, headerName: "File Name", filter: 'agTextColumnFilter', sortable: false, filterParams:{filterOptions: textComparators, maxNumConditions: 1}, flex:1},
    {field: "fileSize", filter: 'agNumberColumnFilter', filterParams:{filterOptions : numComparators, maxNumConditions: 1}, valueFormatter: fileSizeFormatter, flex:1},
    {field: "status",filter: CustomStateFilter, sortable: false, filterParams:{filterOptions : ['Unknown', 'Pending', 'Failed', 'Ready', "Archived", "Purged", "Evicted"] }, flex:1},
    ...additionalColumns,
    {field: "actions", editable: false, filter: false,  headerName: "Actions", sortable: false, cellRenderer: FileActions, cellRendererParams: { gridRef: gridRef, disableButtons: addButtonConfiguration.disableAdd}, flex:1},
  ]);

  const refreshTable = () => {
    gridRef.current.api.refreshInfiniteCache();
  }
      
  const rowSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    
    if(handleRowSelect != null)
    {
      handleRowSelect(selectedRows);
    }
    if(selectedRows.length > 0)
    {
      setOpen(true);
    }
    else
    {
      setOpen(false);
    }
    
  },[]);
  

  const onGridReady = useCallback( async (params) => {
    const dataSource = {
      rowCount: undefined,
      getRows: getRows
    };
    params.api.setGridOption('datasource', dataSource);
  });
  
  const handleAddFilesToTable = async (filesToUpload) => 
  {
    refreshTable();
  }

  const fileTableConfiguration = {
    gridId: gridId,
    defaultPageSize: defaultPageSize,
    pageSizeOptions: pageSizeOptions,
    colDefs: colDefs,
    onGridReady: onGridReady,
    rowSelectionChanged: rowSelectionChanged,
    gridRef: gridRef
  }
  return<>
  <Col xs={12-columnConfiguration.xs} sm={12-columnConfiguration.sm} md={12-columnConfiguration.md} lg={12-columnConfiguration.lg}>
    <div className="bottom-right-container">
      <BatchActionAccordion open={open} batchAction={<BatchFileActions gridRef={gridRef}/>}/>
      {
        !addButtonConfiguration.hideAdd &&  <Button className="button-table" disabled={addButtonConfiguration.disableAdd} onClick={() => setShowAddFileModal(true)}>Add</Button>
      }
      <AddFilesModal showModal={showAddFileModal} setShowModal={setShowAddFileModal} refreshFilesTable={refreshTable}/>
    </div>
  </Col>
    <FilesTable tableSize={tableSize} fileTableConfiguration={fileTableConfiguration}/>
    <UpdateService updateDataFunction={refreshTable} gridRef={gridRef} active={true}/>   
  </>
}