import React from 'react';
import { Accordion, Button, ButtonGroup, Container, Row, Col, Table  } from 'react-bootstrap';

import { CenteredHeaderCell, CenteredCell } from './../StyledCells';
export const JobAccess  = ({jobData}) =>
{   
 
    return(
        <Container fluid className="p-5 bg-light">
            <Row xs={12} sm={12} md={12} lg={12}>
            <Col xs={{offset:10, span:2}} sm={{offset:10, span:2}} md={{offset:10, span:2}} lg={{offset:10, span:2}}>
                    <Button variant='primary'>Add User</Button>
                </Col>
            </Row>
            
            <Accordion className="bg-light">
                <Accordion.Item eventKey="0" className="bg-light">
                    <Accordion.Header className="bg-light">John Libront</Accordion.Header>
                    <Accordion.Body className="bg-light">
                    <Table striped  bordered hover size = "sm">
                        <thead>
                            <tr>
                                <CenteredHeaderCell cellContent={"Access"}/>
                                <CenteredHeaderCell cellContent={"Access Level"}/>
                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr><CenteredCell cellContent={"View"}/>
                            <CenteredCell cellContent={"Enabled"}/>
                            </tr>
                            <tr><CenteredCell cellContent={"Edit"}/>
                            <CenteredCell cellContent={"Enabled"}/>
                            </tr>
                        </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="bg-light">
                    <Accordion.Header className="bg-light">Srinu Penubarthi</Accordion.Header>
                    <Accordion.Body className="bg-light">
                    <Table striped  bordered hover size = "sm">
                        <thead>
                            <tr>
                                <CenteredHeaderCell cellContent={"Access"}/>
                                <CenteredHeaderCell cellContent={"Access Level"}/>
                            
                            </tr>
                        </thead>
                        <tbody>
                        <tr><CenteredCell cellContent={"View"}/>
                            <CenteredCell cellContent={"Enabled"}/>
                            </tr>
                            <tr><CenteredCell cellContent={"Edit"}/>
                            <CenteredCell cellContent={"Disabled"}/>
                            </tr>
                        </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="bg-light">
                    <Accordion.Header className="bg-light">Lupco Blazeski</Accordion.Header>
                    <Accordion.Body className="bg-light">
                    <Table striped  bordered hover size = "sm">
                        <thead>
                            <tr>
                                <CenteredHeaderCell cellContent={"Access"}/>
                                <CenteredHeaderCell cellContent={"Access Level"}/>
                            
                            </tr>
                        </thead>
                        <tbody>
                        <tr><CenteredCell cellContent={"View"}/>
                            <CenteredCell cellContent={"Enabled"}/>
                            </tr>
                            <tr><CenteredCell cellContent={"Edit"}/>
                            <CenteredCell cellContent={"Disabled"}/>
                            </tr>
                        </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
                
            </Accordion>
            
        </Container>
    )
    
}