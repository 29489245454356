
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useDummyPermissionContext } from "../../auth/AuthContext";
import { batchDeleteJobs, batchCopyJobs, batchStateChange } from "../../apis/jobApi";
export const BatchJobActions = ({gridRef, setOpen, setGridKey}) =>
{
    const { hasPermission } = useDummyPermissionContext();

    const handleBatchDownload = async () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        
    }

    const handleBatchStateChange = async (newJobState) => {
        const selectedRows = gridRef.current.api.getSelectedRows();
      
        const response = await batchStateChange(newJobState, selectedRows);
        gridRef.current.api.refreshInfiniteCache();

    }

    const handleBatchDelete = async () => {
        handleBatchStateChange("archive");
    }

    const handleBatchCopy = async () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        // const response = await batchCopyJobs(selectedRows);
        
        // // //using gridref api  to select nodes
        // setGridKey(`gr1id-key-${Math.random()}`);
        // //gridRef.current.api.purgeInfiniteCache();
        // setOpen(false)
    }

    return (
        <ButtonGroup>
                <Button className="button-table" variant="primary" disabled={hasPermission}><i className="bi bi-play-fill" onClick={() => handleBatchStateChange("start")}></i></Button>
                <Button className="button-table" variant="primary" disabled={hasPermission}><i className="bi bi-pause-fill" onClick={() => handleBatchStateChange("pause")}></i></Button>
                <Button className="button-table" variant="primary" disabled={hasPermission}><i className="bi bi-stop-fill" onClick={() => handleBatchStateChange("stop")}></i></Button>
                <Button className="button-table" variant="primary" disabled={hasPermission}><i className="bi bi-trash" onClick={handleBatchDelete}></i></Button>            
        </ButtonGroup>
    )
}