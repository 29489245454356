import { useGridFilter } from "ag-grid-react";
import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
export const CustomStateFilter = (props) => 
{
    const {model, onModelChange, getValue, colDef} = props;
    const filterOptions = colDef.filterParams.filterOptions;
    const valueChanged = useCallback( event => {
        const newValue = event.target.value;
        onModelChange(newValue == '' ? null : {filter: newValue, type: '='});
    })

    const doesFilterPass = useCallback( params => {
        return true;
    })

    return <div>
        {
            filterOptions.map((option) => {
                return (
                    <Form.Check type="radio" label={option} value={option} checked={model?.filter === option} onChange={valueChanged}></Form.Check>
                );
            })
        }
        <Button className="jobAction button-table" value = "" onClick={valueChanged}>Off</Button>       
    </div>
}
