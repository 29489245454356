import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
export const UpdateService = ({updateDataFunction, gridRef, active}) => {
    const initialTime = 10;
    const [time, setTime] = useState(initialTime);
    
    useEffect(() => { 
      if(time > 0){
        if(active)
        {
          const timerId = setInterval(() => {
            setTime(time - 1);
          }, 1000);
          return () => clearInterval(timerId);
        }
        
      }
      else
      {
        updateDataFunction();
        setTime(initialTime);
      }
    }, [time, updateDataFunction, active]);

    const handleRefresh = async () => {
      updateDataFunction();
      setTime(initialTime);
    };

    const formatTime = (totalSeconds) => {
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    
    return <Row xs={12} sm={12} md={12} lg={12}>
      <Col lg={{offset:8, span:4}} md={{offset:7, span:5}} className="small-text text-end d-flex justify-content-end" style={{"textAlign": "end"}} >
        <Button name="refresh" className="jobAction button-table" onClick={handleRefresh} label="refresh">
          Time Until Refresh: {formatTime(time)} <i className="bi bi-arrow-clockwise"/>
        </Button>
      </Col>
    </Row>; // This component does not render anything
};

