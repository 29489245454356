import React, {useEffect, useState} from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, Outlet } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import { getUser } from '../auth';

import { Button, Container, Nav, Navbar, Offcanvas, Row } from 'react-bootstrap';

import { NavigationBar } from './Navigation/NavigationBar';
export const Root = () => {
    const auth = useAuth();

  const handleLogout = async () => {
    await auth.removeUser();
    auth.signoutRedirect();
    
  }

  
  useEffect(() => {
    const handleStorageChange = () => {
      if(getUser() === null){
        auth.signoutRedirect();
      }
    }
    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    }

  }, [])
  return <>
  <Container fluid className='root_container'>
    <NavigationBar/>
    <Container fluid className="content_container"> 
      <Row className="flex-grow-1">
        <Outlet/>
      </Row>
    </Container>
    <Container fluid className="footer_container">
      <footer className="footer mt-auto pt-2">
        <div className="d-flex justify-content-between">
            <div style={{ position: 'fixed', bottom: '0', left: '0' }}>
              <p>© {new Date().getFullYear()} Milliman, Inc. All rights reserved.</p>
            </div>  
            <div style={{ position: 'fixed', bottom: '0', right: '0' }}>
              <p>GridStep 4.0.0</p>
            </div>
            
          </div>
      </footer>
    </Container>
  </Container>
  </>
}