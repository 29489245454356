import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from './auth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { router } from './library/router';
import { RouterProvider } from 'react-router-dom';
import { DummyPermissionProvider } from './auth/AuthContext';


 const queryClient = new QueryClient();



function App() {
  return <AuthProvider {...oidcConfig}>
      <QueryClientProvider client={queryClient}>
        <DummyPermissionProvider>
          <RouterProvider router={router}/>
        </DummyPermissionProvider>
      </QueryClientProvider>
    </AuthProvider>;
}

export default App;
