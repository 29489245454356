import React from 'react';
import { Button, ButtonGroup, Stack  } from 'react-bootstrap';
import { deleteFile, deleteFiles, downloadFile, downloadFiles } from './../../apis/fileApi';

import { useDummyPermissionContext } from '../../auth/AuthContext';
const DeleteFile  = ({rowProps, disable}) =>
{   

    const { hasPermission } = useDummyPermissionContext();
    const fileData = rowProps.data;
    const handleDeleteFile = async () => 
    {
        const response = await deleteFile(fileData.id)
        rowProps.node.data = response
            
        rowProps.gridRef.current.api.refreshCells({rowNodes: [rowProps.node], force: true, columnIds: ["state"]});
      
    }

    return  <Button name="archive" className="disabled-button button-table"  label="archive" disabled><i class="bi bi-archive"/></Button>
}

export const FileActions = (rowProps) => 
{
    const { hasPermission } = useDummyPermissionContext();

    const handleDownloadFile = async () => {
        await downloadFile(rowProps.data)
        
    }

    return(
        <ButtonGroup>
            <Button  disabled={hasPermission} name="download" className="jobAction button-table" label="download" onClick={handleDownloadFile}><i class="bi bi-download"/></Button>
            <DeleteFile rowProps={rowProps} disable={true}/>
        </ButtonGroup>
    )
}

export const BatchFileActions = ({gridRef}) =>{

    const { hasPermission } = useDummyPermissionContext();
    const handleBatchDownload = async () => {
        const selectedRows = gridRef.current.api.getSelectedRows(); 
        downloadFiles(selectedRows);
    }

    const handleBatchArchive = async () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        const response = await deleteFiles( selectedRows);
        gridRef.current.api.refreshInfiniteCache();
    }
    return(
        
        <Stack direction="horizontal" gap={1} className='action-stack'>
            <Button disabled={hasPermission}  name="download" className="jobAction  button-table" label="download" onClick={handleBatchDownload}><i class="bi bi-download"/></Button>
            <Button disabled={hasPermission} name="archive" className="jobAction  button-table" label="archive" onClick={handleBatchArchive} disabled><i class="bi bi-archive"/></Button>
        </Stack>
        
    )
}